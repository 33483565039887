import React, { FC } from 'react';
import { useId } from 'react-id-generator';
import { Link } from 'gatsby';
import DropdownLanguage from 'gatsby-theme-husky/src/components/DropdownLocation';
import dropdownLocationProps from 'gatsby-theme-husky/src/components/DropdownLocation/mock.json';
import { DropdownLocationProps } from 'gatsby-theme-husky/src/components/DropdownLocation/models.d';
import { FooterContent as OriginalFooterContent } from 'gatsby-theme-husky/src/components/Footer/FooterContent';
import { IFooterContentProps } from 'gatsby-theme-husky/src/components/Footer/models';
import SocialLinks from 'gatsby-theme-husky/src/components/Social';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import classNames from 'classnames';

export const FooterContent: FC<IFooterContentProps> = ({
  navbar,
  additionalInfo,
  additionalLinks,
  additionalLinksTitle,
  socialLinks,
  copyrightNotice,
  footerType,
}) => {
  const { isMobile } = useScreenRecognition();

  if (footerType?.[0]?.type !== 'vanishVictories') {
    return (
      <OriginalFooterContent
        navbar={navbar}
        socialLinks={socialLinks}
        additionalLinks={additionalLinks}
        additionalLinksTitle={additionalLinksTitle}
        copyrightNotice={copyrightNotice}
        additionalInfo={additionalInfo}
        footerType={footerType}
      />
    );
  }

  return (
    <div
      className={classNames('footer__footer-content', {
        [`footer__footer-content--${footerType?.[0]?.type}`]: footerType?.[0]?.type,
      })}
    >
      <div className="footer__nav">
        <div className="footer__left-part">
          <ul>
            {navbar.map(({ properties }) => {
              const { label, url } = properties;
              const [footerItem] = url;
              const [key] = useId();

              return (
                <li className="footer__nav--link-box" key={key}>
                  <Link
                    to={footerItem.url}
                    className="footer__link"
                    data-testid="footer-link"
                    target={footerItem.target}
                    {...(footerItem.target && { rel: 'noopener noreferrer' })}
                  >
                    {label}
                  </Link>
                </li>
              );
            })}
          </ul>

          {!isMobile ? (
            <div className="footer__copyright">
              <DangerouslySetInnerHtml html={copyrightNotice} />
            </div>
          ) : null}
        </div>

        {additionalInfo ? (
          <div className="footer__additional-info">
            <DangerouslySetInnerHtml html={additionalInfo} />
          </div>
        ) : null}

        {additionalLinks?.length ? (
          <div className="footer__nav--additional-links">
            {additionalLinksTitle ? (
              <p className="footer__nav--additional-title">{additionalLinksTitle}</p>
            ) : null}
            <ul className="footer__nav--additional-list">
              {additionalLinks.map(({ properties }) => {
                const { label, url } = properties;
                const [footerItem] = url;
                const [key] = useId();

                return (
                  <li className="footer__nav--link-box" key={key}>
                    <Link
                      to={footerItem.url}
                      className="footer__link"
                      data-testid="additional-footer-link"
                      target={footerItem.target}
                      {...(footerItem.target && { rel: 'noopener noreferrer' })}
                    >
                      {label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
        <div className="footer__link--lang-selector">
          {/* TODO remove 'false' condition and '<div />' element after Country Selector return in this place in phase 2 */}
          {false && <DropdownLanguage {...(dropdownLocationProps as DropdownLocationProps)} />}
          <SocialLinks socialLinks={socialLinks} />
        </div>
      </div>

      {isMobile ? (
        <div className="footer__copyright">
          <DangerouslySetInnerHtml html={copyrightNotice} />
        </div>
      ) : null}
    </div>
  );
};

export default FooterContent;
